import { useEffect, useState } from 'react';
import { WEBSITES, WEBSITES_DATA, Social } from 'utils/constants';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import useSkeletonLoader from 'hooks/useSkeletonLoader';
import { Skeleton } from './skeleton';

interface WebsiteIconProps {
  website: string;
  onClick?: () => void;
}

const WebsiteIcon = ({ website, onClick }: WebsiteIconProps) => {
  const [site, setSite] = useState<Social>(WEBSITES_DATA.website);
  const { loading } = useSkeletonLoader();

  useEffect(() => {
    const fetchDataWithDelay = async () => {
      Object.values(WEBSITES).forEach((social) => {
        if (website.includes(social)) {
          setSite(WEBSITES_DATA[social]);
        }
      });
    };

    fetchDataWithDelay();
  }, [website]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipContent>{site?.name}</TooltipContent>
        <TooltipTrigger>
          {loading ? (
            <Skeleton className="size-6" />
          ) : (
            <a
              href={website}
              target="_blank"
              className="size-6 flex items-center justify-center"
              rel="noreferrer"
              onClick={handleClick}
            >
              {site && site.icon && <site.icon />}
            </a>
          )}
        </TooltipTrigger>
      </Tooltip>
    </TooltipProvider>
  );
};

export { WebsiteIcon };
