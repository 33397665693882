import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { colors } from './colors';
import InterRegular from '../assets/fonts/Inter/Inter-Regular.woff2';
import InterMedium from '../assets/fonts/Inter/Inter-Medium.woff2';
import InterSemiBold from '../assets/fonts/Inter/Inter-SemiBold.woff2';
import InterExtraBold from '../assets/fonts/Inter/Inter-ExtraBold.woff2';

const DEFAULT_REM_UNIT = 16;
const DEFAULT_FONT_FAMILY = "'Inter', sans-serif";

declare module '@mui/material/styles' {
  interface Palette {
    textPrimary: string;
    textSecondary: string;
    grey100: string;
    grey200: string;
    grey300: string;
    white: string;
    black: string;
    successLight: string;
  }

  interface PaletteOptions {
    textPrimary: string;
    textSecondary: string;
    grey100: string;
    grey200: string;
    grey300: string;
    white: string;
    black: string;
    successLight: string;
  }

  interface TypographyVariants {
    heading: React.CSSProperties;
    subheading: React.CSSProperties;
    body1: React.CSSProperties;
    body2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    heading?: React.CSSProperties;
    subheading?: React.CSSProperties;
    body1?: React.CSSProperties;
    body2?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    heading: true;
    subheading: true;
    body1: true;
    body2: true;
  }
}

const themeMUI = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    textPrimary: colors.textPrimary,
    textSecondary: colors.textSecondary,
    info: {
      main: colors.info,
    },
    grey100: colors.grey100,
    grey200: colors.grey200,
    grey300: colors.grey300,
    white: colors.white,
    black: colors.black,
    error: {
      main: colors.error,
    },
    success: {
      main: colors.success,
    },
    successLight: colors.successLight,
  },
  typography: {
    fontFamily: DEFAULT_FONT_FAMILY,
    htmlFontSize: DEFAULT_REM_UNIT,
    heading: {
      fontSize: '1.125rem',
      lineHeight: 1.2,
      fontWeight: 600,
      color: colors.textPrimary,
      fontFamily: DEFAULT_FONT_FAMILY,
    },
    subheading: {
      fontSize: '1rem',
      lineHeight: 1.2,
      fontWeight: 500,
      color: colors.textPrimary,
      fontFamily: DEFAULT_FONT_FAMILY,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 400,
      color: colors.textPrimary,
      fontFamily: DEFAULT_FONT_FAMILY,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
      fontWeight: 400,
      color: colors.textPrimary,
      fontFamily: DEFAULT_FONT_FAMILY,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Inter'), local('Inter-Regular'), url(${InterRegular}) format('woff2');
        }
        @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
            src: local('Inter'), local('Inter-Medium'), url(${InterMedium}) format('woff2');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Inter'), local('Inter-Regular'), url(${InterSemiBold}) format('woff2');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('Inter'), local('Inter-Medium'), url(${InterExtraBold}) format('woff2');
        }
      `,
    },
  },
});

const theme = responsiveFontSizes(themeMUI);

export default theme;
