import { Carousel, CarouselContent, CarouselItem, CarouselApi } from '@/components/ui/carousel';
import { Headline as HeadlineProps } from 'api/company/company.model';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { compareHeadlinesByDate } from '@/utils/utils';
import Headline from '@/components/shared/headline';
import { useEffect, useMemo, useState } from 'react';
import { Button } from '@/components/ui/button';
import { ReactComponent as ChevronRightSvg } from 'assets/icons/right-chevron.svg';
import { ReactComponent as ChevronLeftSvg } from 'assets/icons/left-chevron.svg';
import { Skeleton } from '@/components/ui/skeleton';

const CAROUSEL_OPTIONS = {
  slidesToScroll: 3,
  breakpoints: { '(max-width: 920px)': { slidesToScroll: 1 } },
};

const ARROWS_CLASSNAME =
  'p-2 border bg-transparent border-gray-300 border-solid rounded-full hover:bg-gray-50';

interface HeadlinesListProps {
  headlines: HeadlineProps[] | null;
  loading: boolean;
}

const HeadlinesList = ({ headlines, loading }: HeadlinesListProps) => {
  const sortedHeadlineArray = headlines ? [...headlines].sort(compareHeadlinesByDate) : [];
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const itemsPerSlide =
    window.innerWidth < 920 ? 1 : headlines && headlines.length >= 3 ? 3 : headlines?.length;
  const showNavigation =
    (headlines && headlines?.length > 3) ||
    (headlines && headlines.length > 1 && window.innerWidth < 920);

  const itemClassName = useMemo(() => {
    return `w-${itemsPerSlide === 1 ? 'full' : `1/${itemsPerSlide}`} basis-1/${itemsPerSlide}`;
  }, [itemsPerSlide]);

  useEffect(() => {
    if (!api) {
      return;
    }
    setCurrent(api.selectedScrollSnap());
  }, [api]);

  const handleNext = () => {
    if (!api) {
      return;
    }
    api?.scrollNext();
    setCurrent(api.selectedScrollSnap());
  };

  const handlePrev = () => {
    if (!api) {
      return;
    }
    api?.scrollPrev();
    setCurrent(api.selectedScrollSnap());
  };

  const handleDotClick = (index: number) => {
    if (!api) {
      return;
    }
    api?.scrollTo(index);
    setCurrent(api.selectedScrollSnap());
  };

  return (
    <>
      <Card className="shadow-custom p-4 flex flex-col gap-5">
        {loading ? (
          <Skeleton className="w-24 h-6" />
        ) : (
          <CardTitle className="text-lg font-bold p-0">Headlines</CardTitle>
        )}
        <CardContent className="p-0">
          {!sortedHeadlineArray || sortedHeadlineArray.length === 0 ? (
            <div>
              <p className="text-center font-semibold text-sm">No Headlines available.</p>
            </div>
          ) : (
            <Carousel
              setApi={setApi}
              opts={{
                ...CAROUSEL_OPTIONS,
                align: 'center',
              }}
            >
              <CarouselContent>
                {sortedHeadlineArray.map((headline) => (
                  <CarouselItem className={itemClassName} key={headline.id}>
                    <Headline key={headline.id} {...headline} />
                  </CarouselItem>
                ))}
              </CarouselContent>
              {showNavigation ? (
                <div className="w-full flex justify-between items-center px-8">
                  <div className="w-1/2 flex gap-5 justify-start">
                    {Array.from({ length: api?.scrollSnapList().length || 0 }).map((_, index) => (
                      <Button
                        key={index}
                        size={'dot'}
                        className={`bg-gray-300 ${current === index ? 'bg-gray-800' : ''}`}
                        onClick={() => handleDotClick(index)}
                      />
                    ))}
                  </div>
                  <div className="w-1/2 flex gap-5 justify-end">
                    <Button
                      onClick={handlePrev}
                      className={ARROWS_CLASSNAME}
                      disabled={current === 0}
                    >
                      <ChevronLeftSvg />
                    </Button>
                    <Button
                      onClick={handleNext}
                      className={ARROWS_CLASSNAME}
                      disabled={
                        current === (api?.scrollSnapList() ? api?.scrollSnapList()?.length : 1) - 1
                      }
                    >
                      <ChevronRightSvg />
                    </Button>
                  </div>
                </div>
              ) : null}
            </Carousel>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export { HeadlinesList };
