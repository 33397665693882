import { Headline } from 'api/company/company.model';
import { parse, sub } from 'date-fns';

// App utils
export const monthDiff = (dateFrom: Date, dateTo: Date): number => {
  let months;
  months = (dateTo.getFullYear() - dateFrom.getFullYear()) * 12;
  months -= dateFrom.getMonth();
  months += dateFrom.getMonth();
  return months <= 0 ? 0 : months;
};

// Function to parse date string to Date object
function parseDate(dateStr: string): Date {
  if (dateStr.match(/^\w{3} \d{1,2}, \d{4}$/)) {
    // Matches "Sep 28, 2023"
    return parse(dateStr, 'MMM dd, yyyy', new Date());
  }
  // Handles relative dates like "2 days ago"
  const [value, unit] = dateStr.split(' ');
  switch (unit) {
    case 'day':
    case 'days':
      return sub(new Date(), { days: parseInt(value, 10) });
    case 'week':
    case 'weeks':
      return sub(new Date(), { weeks: parseInt(value, 10) });
    case 'month':
    case 'months':
      return sub(new Date(), { months: parseInt(value, 10) });
    default:
      return new Date();
  }
}

// Compare function for sorting by date (Headlines array)
export const compareHeadlinesByDate = (a_headline: Headline, b_headline: Headline): number => {
  const dateA = parseDate(a_headline.date);
  const dateB = parseDate(b_headline.date);
  return dateB.getTime() - dateA.getTime(); // Newest first
};
