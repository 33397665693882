import { useRef, useEffect } from 'react';
import UndoSvg from 'assets/icons/system-uicons-undo.svg';
import useCompanies from 'hooks/useCompanies';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';

const UndoToast = () => {
  const { company, companiesAssignments, companiesMap, previousCompany, undoReview } =
    useCompanies();
  const previousCompanyNameRef = useRef<string | null>(null);

  const getPreviousCompanyName = () => {
    if (company && companiesMap) {
      const currentCompanyId = company.id?.toString();
      const currentIndex = companiesAssignments.findIndex(
        (assignment) => assignment.companyAffinityId === currentCompanyId,
      );

      if (currentIndex > 0) {
        const previousAssignment = companiesAssignments[currentIndex - 1];
        const previousCompanyId = previousAssignment.companyAffinityId;
        const prevCompany = companiesMap[previousCompanyId];
        return prevCompany?.name;
      }
    }
    return null;
  };

  useEffect(() => {
    previousCompanyNameRef.current = getPreviousCompanyName();
  }, [company]);

  const handleUndo = () => {
    const currentIndex = companiesAssignments.findIndex(
      (assignment) => assignment.companyAffinityId === company.id?.toString(),
    );

    if (currentIndex > 0) {
      const previousAssignment = companiesAssignments[currentIndex - 1];
      const previousCompanyId = previousAssignment.companyAffinityId;
      const sourcerId = previousAssignment.sourcerAffinityId;

      undoReview(sourcerId, previousCompanyId);
      previousCompany();

      console.log('Undo review and navigation triggered for company: ', previousCompanyId);
    }
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipContent className="text-xs bg-[#6462a1]">
          {previousCompanyNameRef.current || ''}
        </TooltipContent>
        {previousCompanyNameRef.current ? (
          <TooltipTrigger
            onClick={handleUndo}
            className="bg-transparent shadow-none hover:bg-[#6462a1]"
            data-cy="undo-button"
          >
            <img className="size-6 max-w-none" src={UndoSvg} alt="undo" />
          </TooltipTrigger>
        ) : (
          <Button
            onClick={handleUndo}
            className="bg-transparent shadow-none hover:bg-[#6462a1]"
            data-cy="undo-button"
          >
            <img className="size-6 max-w-none" src={UndoSvg} alt="undo" />
          </Button>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};

export default UndoToast;
