import AnthosSvg from 'assets/icons/anthos-logo-black.svg';

const ErrorPage = () => {
  return (
    <div className="h-screen flex flex-col m-auto relative">
      <div className="flex justify-center items-center pt-10">
        <img src={AnthosSvg} alt="Anthos logo" className="p-3 w-52" />
      </div>
      <div className="flex flex-col items-center justify-center h-full gap-10">
        <p className="font-sans font-semibold text-6xl">Oops!</p>
        <div className="flex flex-col gap-3">
          <p className="text-center font-sans font-medium text-3xl">
            {`The page you're looking for is temporarily unavailable.`}
          </p>
          <p className="text-center font-sans font-light text-3xl">
            Reach out IT team for further details at{' '}
            <a href="mailto:support@anthoscapital.com">support@anthoscapital.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export { ErrorPage };
