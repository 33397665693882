import useCompanies from '@/hooks/useCompanies';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { FundingSeries } from '@/api/company/company.model';
import { formatCurrency } from '@/lib/utils';
import { Separator } from '@/components/ui/separator';
import { Button } from '@/components/ui/button';

interface FundingTableProps {
  fundraisingData: FundingSeries[];
}

const FundingTable = ({ fundraisingData }: FundingTableProps) => {
  const { fetchFundraisingData, company, noMoreDeals } = useCompanies();

  const handleGetMoreDeals = async () => {
    await fetchFundraisingData(company.snapshotId, company.id.toString());
  };

  const hasFundraisingData =
    fundraisingData.length && fundraisingData[0].date && fundraisingData[0].round;

  return (
    <div>
      {hasFundraisingData ? (
        <Table className="caption-top">
          <TableCaption className="text-start text-md font-bold text-black m-0">
            Fundraising Data
            <Separator className="my-2" />
          </TableCaption>
          <TableHeader className="border-b border-gray-200 border-solid">
            <TableRow className="text-xs">
              <TableHead>Date</TableHead>
              <TableHead>Amount Raised</TableHead>
              <TableHead>Round Type</TableHead>
              <TableHead>Post-Money Valuation</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {fundraisingData?.map((fund) => (
              <TableRow key={`${fund.date}-${fund.round}`}>
                <TableCell>{fund.date}</TableCell>
                <TableCell>{formatCurrency(fund.amount?.amount, fund.amount?.currency)}</TableCell>
                <TableCell>{fund.round}</TableCell>
                <TableCell>
                  {formatCurrency(fund.valuation?.amount, fund.valuation?.currency)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div>
          <p className="text-center font-semibold text-sm">No Fundraising History available.</p>
        </div>
      )}
      {!noMoreDeals && hasFundraisingData ? (
        <Button
          className="border border-indigo-700 border-solid mt-2 px-4"
          variant="blue"
          size="sm"
          data-cy="more-deals-button"
          onClick={handleGetMoreDeals}
        >
          Get More Deals
        </Button>
      ) : null}
    </div>
  );
};

export { FundingTable };
