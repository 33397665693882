type PathPrefix<T extends string> = T extends '' ? '' : `-${T}`;

type Path<T> = (
  T extends object
    ? { [K in Exclude<keyof T, symbol>]: `${K}${PathPrefix<Path<T[K]>>}` }[Exclude<keyof T, symbol>]
    : ''
) extends infer D
  ? Extract<D, string>
  : never;

const colors = {
  primary: '#6366F1',
  secondary: '#312E81',
  textPrimary: '#111927',
  textSecondary: '#6C737F',
  info: '#CFF9FE',
  grey100: '#F8F9FA',
  grey200: '#A5A8AD',
  grey300: '#4D5761',
  white: '#FFFFFF',
  black: '#000000',
  error: '#F04438',
  success: '#15B79E',
  successLight: '#CCFBEF',
  transparent: 'transparent',
};

export type ThemeColors = typeof colors;
export type ComposedThemeColor = Path<ThemeColors>;
export type ThemeColorFamily = keyof ThemeColors;
export type { Path };
export { colors };
