import { ReactComponent as ChevronRight } from 'assets/icons/prime_chevron-up.svg';

interface NoteProps {
  creator: string;
  content: string;
  date: string;
}

const Note = ({ creator, content, date }: NoteProps) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <ChevronRight />
        <p className="text-sm font-semibold text-gray-500">
          Note by {creator} - {date}
        </p>
      </div>
      <p className="text-sm text-gray-500">{content}</p>
    </div>
  );
};

export default Note;
