/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosInstance,
  AxiosResponse,
  Method,
  InternalAxiosRequestConfig,
  AxiosPromise,
} from 'axios';
import jwt_decode, { JwtPayload } from 'jwt-decode';

export interface Request {
  headers?: Record<string, string>;
  data?: any;
  params?: any;
}

export class HttpClient {
  private httpClient: AxiosInstance;

  private token = '';

  constructor() {
    this.httpClient = axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      timeout: 60000,
    });

    this.httpClient.interceptors.request.use(this.handleRequestUse.bind(this));
    this.httpClient.interceptors.response.use(this.handleResponseUse.bind(this));
  }

  private logout() {
    sessionStorage.removeItem('token');
    this.token = '';
    window.location.href = '/';
  }

  private isTokenExpired() {
    const token = sessionStorage.getItem('token') || this.token;
    if (!token) {
      return true;
    }
    const decodedToken: JwtPayload = jwt_decode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp) return decodedToken.exp < currentTime;
    return false;
  }

  private handleRequestUse(config: InternalAxiosRequestConfig) {
    // handle request interceptor logic here
    if (this.isTokenExpired()) {
      this.logout();
    }

    return config;
  }

  private handleResponseUse(config: AxiosResponse) {
    // handle response interceptor logic here
    if (config.status === 401) {
      this.logout();
    }

    return config;
  }

  private async handleRequest(
    url: string,
    method: Method,
    config: Request = {},
  ): Promise<AxiosResponse<any>> {
    const {
      headers = {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token') || this.token,
      },
      data,
      params,
    } = config;
    return this.httpClient.request({
      url,
      method,
      data,
      params,
      headers,
    });
  }

  public get<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, 'get', config);
  }

  public post<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, 'post', config);
  }

  public put<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, 'put', config);
  }

  public delete<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, 'delete', config);
  }

  public patch<T>(url: string, config: Request = {}): AxiosPromise<T> {
    return this.handleRequest(url, 'patch', config);
  }

  public setAuthToken(token: string) {
    this.token = token;
    sessionStorage.setItem('token', token);
  }
}

export default new HttpClient();
