import useAppNavigation from 'hooks/useAppNavigation';
import { useEffect } from 'react';

const Redirect = ({ url }: { url: string }) => {
  const navigate = useAppNavigation();

  useEffect(() => {
    navigate(url);
  }, [navigate, url]);

  return null;
};

export default Redirect;
