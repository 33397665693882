interface Paths {
  [key: string]: string;
}

export default {
  LOGIN: '/',
  NOT_FOUND: '*',
  COMPANY_PROFILE: '/company-profile/:id',
  NO_MORE_COMPANIES: '/no-more-companies',
} as Paths;
