import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import { SLIDE_BACKWARD, SLIDE_FORWARD } from 'common/constants';
import { useEffect } from 'react';
import useViewTransition from './useViewTransition';
import useAppSelector from './useAppSelector';

interface IOptionsType extends NavigateOptions {
  animation?: AnimationTypes;
}

type AnimationTypes = 'slide-forward' | 'slide-backward' | 'default';

const useAppNavigation = (shouldTransition = true) => {
  const routerNavigate = useNavigate();
  const { shouldNavigate, targetRoute } = useAppSelector((state) => state.navigation);

  const { startViewTransition } = useViewTransition();

  const handleTransitionStart = (animation: AnimationTypes = 'default') => {
    const documentEl = document.documentElement;
    switch (animation) {
      case SLIDE_FORWARD:
        documentEl.classList.add(SLIDE_FORWARD);
        break;
      case SLIDE_BACKWARD:
        documentEl.classList.add(SLIDE_BACKWARD);
        break;

      default:
        break;
    }
  };

  const handleTransitionEnd = (animation: AnimationTypes = 'default') => {
    const documentEl = document.documentElement;
    switch (animation) {
      case SLIDE_FORWARD:
        documentEl.classList.remove(SLIDE_FORWARD);
        break;
      case SLIDE_BACKWARD:
        documentEl.classList.remove(SLIDE_BACKWARD);
        break;
      default:
        break;
    }
  };

  const navigate = async (route: To, options?: IOptionsType) => {
    if (shouldTransition) {
      try {
        handleTransitionStart(options?.animation);
        const transition = startViewTransition(() => routerNavigate(route, options));

        await transition?.finished;
      } finally {
        handleTransitionEnd(options?.animation);
      }
    } else {
      routerNavigate(route, options);
    }
  };

  useEffect(() => {
    if (shouldNavigate) {
      navigate(targetRoute);
    }
  }, [shouldNavigate, targetRoute]);

  return navigate;
};
export default useAppNavigation;
