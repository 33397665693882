import { ErrorPage } from '@/pages/error/errorPage';
import { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { User } from '@/api/user/user.model';
import { RootState } from '@/store';
import store from '@/store/index';
interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

function getCurrentUser(): User | null {
  const state = store.getState() as RootState;
  const user = state.user.user;
  return user || null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const user = getCurrentUser();
    Sentry.setUser({
      id: user?.id,
      email: user?.email,
      affinityId: user?.affinityId,
    });
    Sentry.captureException(error, {
      contexts: {
        react: {
          componentStack: errorInfo.componentStack,
        },
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
