import { Card, CardContent } from './card';
import { Skeleton } from './skeleton';

const CardSkeleton = () => {
  return (
    <Card className="bg-card-grey">
      <CardContent className="p-4">
        <div className="flex flex-col gap-3">
          <Skeleton className="h-6 my-2 w-1/2" />
          <Skeleton className="h-60 w-full" />
        </div>
      </CardContent>
    </Card>
  );
};

export { CardSkeleton };
