import jwt_decode from 'jwt-decode';

export const generateQueryString = (params: { [key: string]: string }): string => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    queryParams.set(key, value);
  });

  return queryParams.toString();
};

declare global {
  interface Window {
    Marker: any;
    instgrm: any;
  }
}

export const setMarkerCredentials = (token: string) => {
  if (window.Marker) {
    const decodedToken: { email: string; family_name: string; given_name: string } =
      jwt_decode(token);
    const { email, family_name: familyName, given_name: givenName } = decodedToken;
    window.Marker.setReporter({ email, fullName: `${givenName} ${familyName}` });
  }
};

export const calculateMonthsDifference = (
  startDate: string | null,
  endDate: Date | null,
): number | string => {
  if (startDate === null || startDate === undefined || endDate === null || endDate === undefined) {
    return 'N/A';
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  const yearDiff = end.getFullYear() - start.getFullYear();
  const monthDiff = end.getMonth() - start.getMonth();

  if (end.getDate() < start.getDate()) {
    return yearDiff * 12 + monthDiff - 1;
  }
  return yearDiff * 12 + monthDiff;
};
