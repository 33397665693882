import { LoaderCircle } from 'lucide-react';
import anthosLogo from 'assets/icons/anthos-logo-black.svg';

const Loader = () => {
  return (
    <div className="flex h-screen w-full justify-center items-center">
      <img src={anthosLogo} alt="anthos logo" />
      <LoaderCircle className="text-sidebar-grey relative animate-spinScaled right-11" />
    </div>
  );
};

export default Loader;
