import { ReactComponent as InstagramSvg } from 'assets/icons/instagram.svg';
import { ReactComponent as TwitterSvg } from 'assets/icons/twitter.svg';
import { ReactComponent as FacebookSvg } from 'assets/icons/facebook.svg';
import { ReactComponent as YoutubeSvg } from 'assets/icons/youtube.svg';
import { ReactComponent as LinkedinSvg } from 'assets/icons/linkedin.svg';
import { ReactComponent as GlobeSvg } from 'assets/icons/globe-22.svg';
import { ReactComponent as AffinitySvg } from 'assets/icons/affinity.svg';
import { ReactComponent as PitchbookSvg } from 'assets/icons/pitchbook.svg';
import { ReactComponent as HarmonicSvg } from 'assets/icons/harmonic.svg';
import { ReactComponent as CrunchbaseSvg } from 'assets/icons/crunchbase.svg';
import { ReactComponent as AngellistSvg } from 'assets/icons/angellist.svg';
import { ReactComponent as WellfoundSvg } from 'assets/icons/wellfound.svg';
import { FC } from 'react';
import { ApexOptions } from 'apexcharts';

export const headlineTitleExample = 'PR Newswire';

export const textControlType = 'inline-radio';

export enum DontPursueDialogLabels {
  Option1 = 'Company is in sector that we don’t like',
  Option2 = 'Not enough demonstrated traction',
  Option3 = 'Market too small',
  Option4 = 'Company has already raised from Tier 1 investors',
  Option5 = 'Lack of confidence in leadership team',
  Option6 = 'Company has already raised too much',
  Option7 = 'We’ve already interacted and decided to pass',
  Option8 = 'Company has exited or closed',
  Option9 = 'Other',
}

export enum ToastType {
  SUCCESS = 'success',
  DANGER = 'danger',
  NONE = 'none',
}

export enum SkipDialogLabels {
  Option1 = 'Someone else on the team is better suited to review',
  Option2 = "I don't have enough information",
  Option3 = 'I need to consult someone else',
  Option4 = 'Missing or inaccurate information',
  Option5 = 'Just trying to clear my assignments so I stop getting Slack DMs',
  Option6 = 'Other',
}

export enum ButtonType {
  Success = 'success',
  Danger = 'danger',
  Disabled = 'disabled',
  Info = 'info',
  Text = 'text',
  Submit = 'submit',
  Guide = 'guide',
  Deals = 'deals',
}

export enum ButtonAction {
  Skip = 'Skip',
  Track = 'Track',
  Reach = 'Reach Out',
  DontPursue = "Don't Pursue",
}

export const EventTypeToButtonActionMap: Record<ButtonAction, string> = {
  [ButtonAction.Skip]: 'SKIP',
  [ButtonAction.Track]: 'TRACK',
  [ButtonAction.Reach]: 'REACH_OUT',
  [ButtonAction.DontPursue]: 'DONT_PURSUE',
};

export enum FundraisingColumns {
  Date = 'Date',
  Amount = 'Amount Raised',
  Round = 'Round Type',
  Valuation = 'Post-Money Valuation',
}

export const chartConfig: ApexOptions = {
  chart: {
    id: 'basic-area',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    categories: [],
    labels: {
      rotate: 0,
    },
    axisBorder: {
      show: true,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      offsetX: -12,
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.6,
      opacityTo: 0.3,
      stops: [0, 100],
    },
  },
  grid: {
    strokeDashArray: 1,
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  legend: {
    show: true,
    labels: {
      useSeriesColors: true,
    },
    fontFamily: 'Inter',
    fontSize: '14px',
    itemMargin: {
      horizontal: 5,
      vertical: 5,
    },
  },
  stroke: {
    width: 1,
    curve: 'straight',
  },
  colors: [
    '#2E93fA',
    '#66DA26',
    '#546E7A',
    '#E91E63',
    '#FF9800',
    '#1F77B4',
    '#AEC7E8',
    '#FF7F0E',
    '#FFBB78',
    '#2CA02C',
    '#98DF8A',
    '#D62728',
    '#FF9896',
    '#9467BD',
    '#C5B0D5',
    '#8C564B',
    '#C49C94',
    '#E377C2',
    '#F7B6D2',
    '#7F7F7F',
    '#C7C7C7',
    '#BCBD22',
    '#DBDB8D',
    '#17BECF',
    '#9EDAE5',
    '#393B79',
    '#637939',
    '#8C6D31',
    '#843C39',
    '#5254A3',
    '#8CA252',
    '#BD9E39',
    '#AD494A',
    '#6B6ECF',
    '#B5CF6B',
    '#E7BA52',
    '#D6616B',
    '#9C9EDE',
    '#CEDB9C',
    '#E7969C',
    '#7B4173',
    '#A55194',
    '#CE6DBD',
    '#DADAEB',
    '#DE9ED6',
  ],
};

export const profileExample = {
  id: 288457469,
  websites: [
    'https://altro.io',
    'https://getperch.app',
    'https://www.crunchbase.com/organization/perch-0bce',
    'https://www.facebook.com/altroapp',
    'https://twitter.com/altro_app',
    'https://anthoscapital.affinity.co/companies/288457469',
  ],
  description:
    'Altro is a free credit service helping people build credit through recurring payments and subscriptions',
  name: 'Altro',
  logo: 'https://assets.harmonic.ai/company_e3cd0ac564063132e94f588239b987429b51041bfba693c5d4212e4c5a91bbc4.png',
  location: 'United States',
  relationshipLead: 104505482,
  yearFounded: '2019',
  interactions: {
    last: {
      date: 'Unknown',
      type: 'Unknown',
      user: 'Unknown',
    },
  },
  funding: {
    lastFundingDate: 'may. 13, 2022',
    lastFundingAmount: {
      amount: '18000000',
      currency: 'USD',
    },
    lastPostValuation: {
      amount: '21000000',
      currency: 'USD',
    },
  },
  fundingHistory: [],
  investors: [
    // ... (lista de inversores)
  ],
  headcount: {
    dates: [
      // ... (lista de fechas)
    ],
    departmentsCounts: [
      // ... (lista de departamentos)
    ],
  },
  notes: [
    {
      id: 'Unknown',
      author: 'Affinity API',
      content:
        '* Youngest board member of a credit union - VP at USC\n* 120k users on waitlist\n* 8k into the app\n* Jay Z backed -- secret launch with Forbes on Instagram (1bn views) went viral\n* 2500 downloads a week\n* Ios Jan 1st\n\nAuto-imported from the Other Information field in Salesforce\n\n\n\n*Imported from historical Salesforce data; replaced Affinity Note ID: 9888298*',
      source: 'Affinity',
      sourceNoteID: '13159835',
      createdAt: 'oct. 25, 2022',
    },
  ],
  headlines: [],
};

export enum WEBSITES {
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  YOUTUBE = 'youtube',
  PITCHBOOK = 'pitchbook',
  HARMONIC = 'harmonic',
  AFFINITY = 'affinity',
  CRUNCHBASE = 'crunchbase',
  WEBSITE = 'website',
  ANGELLIST = 'angel',
  WELLFOUND = 'wellfound',
}

export const WEBSITES_DATA: Record<string, Social> = {
  [WEBSITES.INSTAGRAM]: {
    name: 'Instagram',
    icon: InstagramSvg,
  },
  [WEBSITES.FACEBOOK]: {
    name: 'Facebook',
    icon: FacebookSvg,
  },
  [WEBSITES.TWITTER]: {
    name: 'Twitter',
    icon: TwitterSvg,
  },
  [WEBSITES.LINKEDIN]: {
    name: 'LinkedIn',
    icon: LinkedinSvg,
  },
  [WEBSITES.YOUTUBE]: {
    name: 'Youtube',
    icon: YoutubeSvg,
  },
  [WEBSITES.PITCHBOOK]: {
    name: 'Pitchbook',
    icon: PitchbookSvg,
  },
  [WEBSITES.HARMONIC]: {
    name: 'Harmonic',
    icon: HarmonicSvg,
  },
  [WEBSITES.AFFINITY]: {
    name: 'Affinity',
    icon: AffinitySvg,
  },
  [WEBSITES.CRUNCHBASE]: {
    name: 'Crunchbase',
    icon: CrunchbaseSvg,
  },
  [WEBSITES.WEBSITE]: {
    name: 'Website',
    icon: GlobeSvg,
  },
  [WEBSITES.ANGELLIST]: {
    name: 'Angellist',
    icon: AngellistSvg,
  },
  [WEBSITES.WELLFOUND]: {
    name: 'Wellfound',
    icon: WellfoundSvg,
  },
};

export interface Social {
  name: string;
  icon: FC | string;
}

export const DONT_REASONS = [
  {
    label: DontPursueDialogLabels.Option1,
    id: 1,
  },
  {
    label: DontPursueDialogLabels.Option2,
    id: 2,
  },
  {
    label: DontPursueDialogLabels.Option3,
    id: 3,
  },
  {
    label: DontPursueDialogLabels.Option4,
    id: 4,
  },
  {
    label: DontPursueDialogLabels.Option5,
    id: 5,
  },
  {
    label: DontPursueDialogLabels.Option6,
    id: 6,
  },
  {
    label: DontPursueDialogLabels.Option7,
    id: 7,
  },
  {
    label: DontPursueDialogLabels.Option8,
    id: 8,
  },
  {
    label: DontPursueDialogLabels.Option9,
    id: 9,
  },
];

export const SKIP_REASONS = [
  {
    label: SkipDialogLabels.Option1,
    id: 1,
  },
  {
    label: SkipDialogLabels.Option2,
    id: 2,
  },
  {
    label: SkipDialogLabels.Option3,
    id: 3,
  },
  {
    label: SkipDialogLabels.Option4,
    id: 4,
  },
  {
    label: SkipDialogLabels.Option5,
    id: 5,
  },
  {
    label: SkipDialogLabels.Option6,
    id: 6,
  },
];

export const notAvailableLabel = 'Unknown';

export const noneLabel = 'None';

export const noRelationshipLeadLabel = 'No Relationship Lead Assigned';

export const errorMessage = 'An error occurred';

export const noteContentExample =
  '**Adventure Journey**\n\n' +
  "In *our thrilling adventure*, we discovered the unimaginable. Here's a list of some incredible things we experienced:\n\n" +
  '1. **Deep Exploration**\n' +
  '   - *We dived* into the depths of the ocean, uncovering never-before-seen marine creatures.\n' +
  '   - *Explored* mysterious caves that revealed ancient secrets.\n\n' +
  '2. **Nature Encounter**\n' +
  '   - *We trekked* through dense tropical jungles, marveling at lush flora and fauna.\n' +
  '   - *Observed* majestic birds in their natural habitat, capturing unique moments on our cameras.\n\n' +
  '3. **Local Culture**\n' +
  '   - *We savored* delicious local dishes, each bite was an explosion of authentic flavors.\n' +
  '   - *Danced* to the rhythm of traditional music, immersing ourselves in the rich cultural heritage of the place.\n\n' +
  'It was a journey that challenged our senses and expanded our horizons. Every moment was an adventure, every encounter a lesson. We left with hearts full of gratitude and minds filled with unforgettable memories!';

export const FundingMockData = [
  {
    date: '12/30/2018',
    amount: { amount: '$23.9k', currency: 'USD' },
    round: 'Series B',
    valuation: { amount: '$23.9M', currency: 'USD' },
  },
  {
    date: '11/30/2018',
    amount: { amount: '$23.9k', currency: 'USD' },
    round: 'Series B',
    valuation: { amount: '$23.9M', currency: 'USD' },
  },
  {
    date: '14/30/2018',
    amount: { amount: '$23.9M', currency: 'USD' },
    round: 'Series B',
    valuation: { amount: '$23.9M', currency: 'USD' },
  },
];

export const { VITE_AFFINITY_BASE_URL } = import.meta.env;
