import { Company } from '@/api/company/company.model';
import { Card, CardContent, CardTitle } from '../ui/card';
import html from '@/lib/instagram';
import { useEffect, useState } from 'react';
import { CardSkeleton } from '../ui/cardSkeleton';

interface InstagramProfileProps {
  company: Company;
  name: string;
  loading: boolean;
}

const InstagramProfile = ({ company, name, loading }: InstagramProfileProps) => {
  const [finalHTML, setFinalHTML] = useState('');

  const getInstagramProfile = () => {
    if (!company) return '';
    const instagram = company.websites?.find((website: string) => website.includes('instagram'));
    if (instagram) {
      return instagram.split('/')[3];
    }
    return '';
  };

  const profile = getInstagramProfile();

  useEffect(() => {
    const replacedHTML = html.replaceAll('{PROFILE}', profile);
    setFinalHTML(replacedHTML.replace('{PROFILE_NAME}', name));

    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  }, [profile, name]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://www.instagram.com/embed.js';
    script.id = 'instagram-embed';
    script.async = true;

    document.body.appendChild(script);
  }, [finalHTML]);

  if (loading) return <CardSkeleton />;

  return (
    <Card className="bg-card-grey flex flex-col h-fit">
      <CardTitle className="text-lg font-bold p-4">Instagram</CardTitle>
      <CardContent className="px-4 flex-grow">
        <Card className="px-3 py-6 flex flex-col h-full">
          <CardContent className="p-0">
            {profile ? (
              <div dangerouslySetInnerHTML={{ __html: finalHTML }} />
            ) : (
              <div>
                <p className="text-center font-semibold text-sm">
                  No Instagram profile available for {name}.
                </p>
              </div>
            )}
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export { InstagramProfile };
