import { createSlice } from '@reduxjs/toolkit';

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: { shouldNavigate: false, targetRoute: '' },
  reducers: {
    setNavigationState(state, action) {
      return {
        ...state,
        shouldNavigate: action.payload.shouldNavigate,
        targetRoute: action.payload.targetRoute,
      };
    },
  },
});

export const { setNavigationState } = navigationSlice.actions;
export default navigationSlice.reducer;
