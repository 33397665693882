import { configureStore } from '@reduxjs/toolkit';
import companiesSlice from 'store/slices/companiesSlice';
import userSlice from './slices/userSlice';
import navigationSlice from './slices/navigationSlice';

const store = configureStore({
  reducer: {
    companies: companiesSlice,
    user: userSlice,
    navigation: navigationSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
