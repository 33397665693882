import { DepartmentCount } from '@/api/company/company.model';
import { Card, CardContent, CardTitle } from '../ui/card';
import { Chart } from './chart';
import { CardSkeleton } from '../ui/cardSkeleton';

interface HeadcountCardProps {
  dates: string[];
  headcounts: DepartmentCount[];
  isLoading: boolean;
}

const Headcount = ({ dates, headcounts, isLoading }: HeadcountCardProps) => {
  const chartData = dates.map((date, index) => {
    const areaData: { [key: string]: number } = {};

    headcounts.forEach((department: DepartmentCount) => {
      areaData[department.area] = department.employees[index];
    });

    return { date, ...areaData };
  });
  if (isLoading) return <CardSkeleton />;
  return (
    <Card className="bg-card-grey flex flex-col h-fit">
      <CardTitle className="text-lg font-bold p-4">Headcount Growth</CardTitle>
      <CardContent className="px-4 flex-grow">
        <Chart chartData={chartData} />
      </CardContent>
    </Card>
  );
};

export { Headcount };
