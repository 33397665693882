import { Note as INote } from 'api/company/company.model';
import { Card, CardContent, CardTitle } from '../ui/card';
import { CardSkeleton } from '../ui/cardSkeleton';
import Note from './note';

interface AffinityNotesCardProps {
  notes: INote[];
  loading: boolean;
}

const parseDate = (dateString: string): Date => {
  const [day, month, year] = dateString.split('/').map(Number);
  return new Date(year, month - 1, day);
};

const AffinityNotes = ({ notes, loading }: AffinityNotesCardProps) => {
  const sortedNotes = [...notes].sort(
    (a, b) => parseDate(b.createdAt).getTime() - parseDate(a.createdAt).getTime(),
  );
  if (loading) return <CardSkeleton />;
  return (
    <Card className="bg-card-grey flex flex-col h-fit">
      <CardTitle className="text-lg font-bold p-4">Affinity Notes</CardTitle>
      <CardContent className="px-4 flex-grow">
        <Card className="px-3 py-6 flex flex-col h-full">
          <CardContent className="p-0">
            <div className="flex flex-col gap-5">
              {sortedNotes && sortedNotes.length > 0 ? (
                sortedNotes.map((note) => (
                  <Note
                    key={note.id}
                    creator={note.author}
                    content={note.content}
                    date={note.createdAt}
                  />
                ))
              ) : (
                <div>
                  <p className="text-center font-semibold text-sm">No notes available.</p>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export { AffinityNotes };
