import { User } from '@/api/user/user.model';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Checkbox } from '@/components/ui/checkbox';
import { Textarea } from '@/components/ui/textarea';
import useCompanies from '@/hooks/useCompanies';
import useUser from '@/hooks/useUser';
import { useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { ButtonAction, SKIP_REASONS } from 'utils/constants';
import { Separator } from '@/components/ui/separator';
import { Button } from '@/components/ui/button';

interface ReasonsAlertDialogProps {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  reasons: Array<{ label: string; id: number }>;
  actionType: ButtonAction;
  availableUsers?: Array<User>;
  textAreaReason?: number;
  feedbackToastAction?: () => void;
}

const ReasonsAlertDialog = ({
  isOpen,
  reasons,
  setIsOpen,
  actionType,
  availableUsers,
  textAreaReason,
  feedbackToastAction,
}: ReasonsAlertDialogProps) => {
  const { nextCompany, submitCompanyEvent, company } = useCompanies();
  const { user: currentUser } = useUser();
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const posthog = usePostHog();

  const toggleOption = (id: number) => {
    if (selectedOptions.includes(id)) {
      if (id === reasons[reasons.length - 1].id && !!notes) return;
      setSelectedOptions(selectedOptions.filter((option) => option !== id));
    } else {
      setSelectedOptions([...selectedOptions, id]);
    }
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
    if (selectedOptions.length === 0) {
      toggleOption(reasons[reasons.length - 1].id);
    }
  };

  const handleSubmit = () => {
    if (selectedOptions.length > 0) {
      const selectedOptionsLabels = reasons
        .filter((reason) => selectedOptions.includes(reason.id))
        .map((reason) => reason.label);
      submitCompanyEvent(
        actionType,
        company.snapshotId,
        selectedOptionsLabels,
        notes,
        company.listEntryId,
        selectedOptionsLabels.includes(SKIP_REASONS[0].label)
          ? selectedUser?.affinityId
          : undefined,
      );
      nextCompany();
      setSelectedOptions([]);
      setIsOpen(false);
      posthog?.capture(actionType, {
        company_id: company.id,
        company_name: company.name,
      });
      if (feedbackToastAction) {
        feedbackToastAction();
      }
    }
  };
  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(false)}>
      <DialogContent className="p-10 flex flex-col gap-4">
        <DialogHeader>
          <div className="flex w-full justify-between items-center pb-4">
            <DialogTitle className="text-[28px] font-sans font-bold">Any reason?</DialogTitle>
          </div>
        </DialogHeader>
        <DialogDescription asChild>
          <div className="flex flex-col gap-4">
            {reasons.map((reason) => (
              <div className="flex items-center gap-2" key={reason.id}>
                <Checkbox
                  data-cy={`checkbox-${reason.id}`}
                  checked={selectedOptions.includes(reason.id)}
                  onCheckedChange={() => toggleOption(reason.id)}
                  id={reason.id.toString()}
                  className="border-black border-solid border rounded-sm hover:bg-slate-50 data-[state=checked]:bg-[#6366f1]"
                />
                <p className="text-sm text-black font-sans">{reason.label}</p>
              </div>
            ))}
            {actionType && textAreaReason && selectedOptions.includes(textAreaReason) && (
              <Textarea
                value={notes}
                onChange={handleNotesChange}
                name="Notes"
                cols={30}
                rows={5}
                data-cy="dialog-text-area"
              />
            )}
            {availableUsers && availableUsers.length > 0 && selectedOptions.includes(1) && (
              <>
                <Separator />
                <h2 className="text-sm text-black font-sans">Reassign review to: (optional)</h2>
                <Select
                  onValueChange={(value) =>
                    setSelectedUser(availableUsers.find((user) => user.id === value) || null)
                  }
                  data-cy="dialog-reassign-select"
                >
                  <SelectTrigger className="border-black border-solid border">
                    <SelectValue placeholder="Select a user" />
                  </SelectTrigger>
                  <SelectContent>
                    {availableUsers
                      .filter((user) => user.id !== currentUser.user.id)
                      .map((user) => (
                        <SelectItem
                          className="text-sm font-sans hover:cursor-pointer hover:bg-slate-50"
                          key={user.id}
                          value={user.id}
                        >
                          {user.name}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </>
            )}
          </div>
        </DialogDescription>
        <div className="w-full flex justify-end gap-4">
          <DialogClose
            className="font-sans text-sm font-semibold border border-solid px-4 py-2 rounded-md"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </DialogClose>
          <Button
            className="font-sans text-sm font-semibold bg-[#6366f1]"
            disabled={selectedOptions.length === 0}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { ReasonsAlertDialog };
