import useCompanies from '@/hooks/useCompanies';
import useUser from '@/hooks/useUser';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { ButtonAction } from 'utils/constants';
import { Button } from '@/components/ui/button';
import dontPursue from 'assets/icons/dontPursue.svg';
import reachOut from 'assets/icons/reachOut.svg';
import skip from 'assets/icons/skip.svg';
import track from 'assets/icons/track.svg';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LooksThreeIcon from '@mui/icons-material/Looks3';
import LooksFourIcon from '@mui/icons-material/Looks4';
import { Separator } from '../ui/separator';
import { cn } from '@/lib/utils';
import UndoToast from '../shared/undoToast';

interface HeaderProps {
  isModalOpen: boolean;
  toggleDialogs: (actionType: string) => void;
  onReachOut: () => void;
}

const BUTTON_CLASSNAME = 'relative h-12 w-36';

const Header = ({ toggleDialogs, isModalOpen, onReachOut }: HeaderProps) => {
  const { nextCompany, company, companiesAssignments, submitCompanyEvent } = useCompanies();
  const { user } = useUser();
  const posthog = usePostHog();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (isModalOpen) return;
    switch (event.keyCode) {
      case 49:
        nextCompany();
        posthog?.capture('submit_review', {
          action_type: ButtonAction.Reach,
          company_id: company.id,
          company_name: company.name,
        });
        onReachOut();
        break;
      case 50:
        nextCompany();
        posthog?.capture('submit_review', {
          action_type: ButtonAction.Track,
          company_id: company.id,
          company_name: company.name,
        });
        break;
      case 51:
        toggleDialogs(ButtonAction.Skip);
        posthog?.capture('skiped', {
          company_id: company.id,
          company_name: company.name,
        });
        break;
      case 52:
        toggleDialogs(ButtonAction.DontPursue);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [nextCompany, toggleDialogs, isModalOpen]);

  const reachOutHandler = () => {
    submitCompanyEvent(ButtonAction.Reach, company.snapshotId, [], '', company.listEntryId);
    nextCompany();
    posthog?.capture('submit_review', {
      action_type: ButtonAction.Reach,
      company_id: company.id,
      company_name: company.name,
    });
    onReachOut();
  };

  const trackHandler = () => {
    submitCompanyEvent(ButtonAction.Track, company.snapshotId, [], '', company.listEntryId);
    nextCompany();
    posthog?.capture('submit_review', {
      action_type: ButtonAction.Track,
      company_id: company.id,
      company_name: company.name,
    });
  };

  const skipHandler = () => {
    toggleDialogs(ButtonAction.Skip);
  };

  const dontPursueHandler = () => {
    toggleDialogs(ButtonAction.DontPursue);
  };

  return (
    <header className="absolute z-50 top-0 left-0 right-0 bg-header-blue px-8 py-4 md:fixed md:shadow-lg">
      <div className="flex justify-between items-center max-w-8xl mx-auto min-h-12">
        <h2 className="text-gray-100">
          Welcome, <b>{user.user.name}</b>
        </h2>
        <div className="flex gap-8 items-center">
          <div className="hidden gap-4 md:flex" data-cy="header-buttons">
            <Button
              className={cn(
                BUTTON_CLASSNAME,
                'hover:bg-button-success hover:border-button-success',
              )}
              variant={'header'}
              onClick={reachOutHandler}
            >
              <div className="flex gap-2">
                <img src={reachOut} alt="icon" />
                <span>Reach Out</span>
              </div>
              <LooksOneIcon className="absolute right-px bottom-px" />
            </Button>
            <Button
              className={cn(BUTTON_CLASSNAME, 'hover:bg-button-track hover:border-button-track')}
              variant={'header'}
              onClick={trackHandler}
            >
              <div className="flex gap-2">
                <img src={track} alt="icon" />
                <span>Track</span>
              </div>
              <LooksTwoIcon className="absolute right-px bottom-px" />
            </Button>
            <Button
              className={cn(BUTTON_CLASSNAME, 'hover:bg-gray-400 hover:border-gray-400')}
              variant={'header'}
              onClick={skipHandler}
            >
              <div className="flex gap-2">
                <img src={skip} alt="icon" />
                <span>Skip</span>
              </div>
              <LooksThreeIcon className="absolute right-px bottom-px" />
            </Button>
            <Button
              className={cn(
                BUTTON_CLASSNAME,
                'hover:bg-button-dontPursue hover:border-button-dontPursue',
              )}
              variant={'header'}
              onClick={dontPursueHandler}
            >
              <div className="flex gap-2">
                <img src={dontPursue} alt="icon" />
                <span>{"Don't Pursue"}</span>
              </div>
              <LooksFourIcon className="absolute right-px bottom-px" />
            </Button>
          </div>
          <Separator
            orientation="vertical"
            className="hidden md:block h-12 bg-header-blue-light -mx-4"
          />
          <UndoToast />
          <div
            className="flex gap-2 text-white text-xs justify-center items-center md:flex-col md:items-start"
            data-cy="reviews-container"
          >
            <span className="text-gray-200">Weekly Review</span>
            <span className="text-lg md:text-2xl">
              {companiesAssignments.findIndex(
                (assignment) => assignment.companyAffinityId === company.id?.toString(),
              ) + 1}
              /{companiesAssignments.length}
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export { Header };
