import { WEBSITES } from 'utils/constants';
import { Skeleton } from '@/components/ui/skeleton';
import { WebsiteIcon } from '@/components/ui/websiteIcon';

interface AffinityListProps {
  prospectOrigin: string;
  isLoading: boolean;
}

const ProspectOrigin = ({ prospectOrigin, isLoading }: AffinityListProps) => {
  const getIcon = () => {
    if (isLoading) {
      return <Skeleton className="size-10" />;
    }

    switch (prospectOrigin) {
      case 'Harmonic':
        return <WebsiteIcon website={WEBSITES.HARMONIC} />;
      case 'Affinity':
        return <WebsiteIcon website={WEBSITES.AFFINITY} />;
      case 'Intern':
        return <WebsiteIcon website="" />;
      default:
        return <WebsiteIcon website="" />;
    }
  };
  return (
    <div className="flex gap-3 p-5">
      <div className="flex justify-center items-center">{getIcon()}</div>
      <div>
        {isLoading ? (
          <div className="flex flex-col gap-2">
            <Skeleton className="w-28 h-7 bg-gray-300" />
            <Skeleton className="bg-gray-200 w-28 h-5" />
          </div>
        ) : (
          <div>
            <h3 className="text-sm">Prospect origin</h3>
            <p className="text-lg font-semibold">{prospectOrigin}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export { ProspectOrigin };
