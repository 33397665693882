import * as Sentry from '@sentry/react';

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
const VERSION = __APP_VERSION__;

const setupSentry = () => {
  console.log('VERSION', VERSION);
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: `forge-fe@${VERSION}`,
    enabled: ENVIRONMENT === 'production',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event) {
      // Sanitize sensitive data
      if (event.request && event.request.headers) {
        delete event.request.headers['Authorization'];
      }
      return event;
    },
  });
};

export default setupSentry;
