import { ReactComponent as NoMoreCompaniesIcon } from '@/assets/no-more-companies.svg';
import useUser from '@/hooks/useUser';
import { useEffect } from 'react';

const NoMoreCompanies: React.FC = () => {
  const { user, fetchUserDataFromAPI } = useUser();

  useEffect(() => {
    fetchUserDataFromAPI();
  }, [fetchUserDataFromAPI]);

  return (
    <div className="text-center font-sans flex items-center justify-center flex-col gap-4 h-screen">
      <NoMoreCompaniesIcon className="mb-2 md:mb-4" />
      <h1 className="text-2xl font-semibold mb-2 md:text-4xl md:mb-4">
        Great news, {user.user.name || 'Default User'}! You&apos;re all caught up for the week.
      </h1>
      <p className="text-base md:text-2xl">
        We&apos;ll notify you as soon as new companies become available for evaluation.
      </p>
    </div>
  );
};

export default NoMoreCompanies;
