import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import companyApi from 'api/company/company.api';
import { Company, CompanyAssignment, HeadlineFeedbackData } from 'api/company/company.model';
import paths from 'routes/paths';
import { errorMessage } from 'utils/constants';
import { setNavigationState } from './navigationSlice';
import { FundingSeries } from '../../api/company/company.model';

export interface CompaniesState {
  selectedCompanyId: number;
  companiesMap: { [key: string]: Company };
  companiesAssignments: CompanyAssignment[];
  isLoading: boolean;
  error: string | null;
  noMoreDeals: boolean;
  selectedCompanyListEntryId: string;
  fetchProgress: number;
  isFetchingAdditional: boolean;
}

const INITIAL_STATE = {
  selectedCompanyId: 0,
  companiesAssignments: [],
  companiesMap: {},
  isLoading: false,
  error: null,
  noMoreDeals: false,
  selectedCompanyListEntryId: '',
  fetchProgress: 0,
  isFetchingAdditional: false,
} as CompaniesState;

export const getOneCompany = createAsyncThunk('company/getOneCompany', companyApi.getOneCompany);
export const undoReview = createAsyncThunk('company/undoReview', companyApi.undoReview);
export const postEvent = createAsyncThunk('company/postEvent', companyApi.submitEvent);
export const postHeadlineFeedback = createAsyncThunk(
  'company/postHeadlineFeedback',
  async (arg: HeadlineFeedbackData, thunkAPI) => {
    thunkAPI.dispatch(setHeadlineFeedback(arg));
    return companyApi.submitHeadlineFeedback(arg);
  },
);

export const getFundraisingData = createAsyncThunk(
  'company/getFundraisingData',
  companyApi.getFundraisingData,
);
export const getAllCompanies = createAsyncThunk(
  'company/getAllCompanies',
  async (_arg, thunkAPI) => {
    const response: CompanyAssignment[] = await companyApi.getAllCompanies();

    if (response.filter((assignment) => !assignment.hasReview).length === 0) {
      thunkAPI.dispatch(
        setNavigationState({ shouldNavigate: true, targetRoute: paths.NO_MORE_COMPANIES }),
      );
    } else {
      const firstAssignmentWithoutReview = response.find((assignment) => !assignment.hasReview);

      if (firstAssignmentWithoutReview) {
        thunkAPI.dispatch(setSelectedCompany(firstAssignmentWithoutReview));
        thunkAPI.dispatch(getOneCompany(firstAssignmentWithoutReview.companyAffinityId));

        // Dispatch a new action to fetch additional companies
        thunkAPI.dispatch(fetchAdditionalCompanies(response.slice(1)));
      }
    }

    return response;
  },
);

// New async thunk for fetching additional companies
export const fetchAdditionalCompanies = createAsyncThunk(
  'company/fetchAdditionalCompanies',
  async (companies: CompanyAssignment[], thunkAPI) => {
    for (const company of companies) {
      await thunkAPI.dispatch(getOneCompany(company.companyAffinityId));
    }
  },
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedCompany: (state, action: PayloadAction<CompanyAssignment>) => {
      return {
        ...state,
        noMoreDeals: false,
        selectedCompanyId: parseInt(action.payload.companyAffinityId, 10),
        selectedCompanyListEntryId: action.payload.listEntryId,
      };
    },
    setFetchProgress: (state, action: PayloadAction<number>) => {
      state.fetchProgress = action.payload;
    },
    setHeadlineFeedback: (state, action: PayloadAction<HeadlineFeedbackData>) => {
      const { headlineId, sourcerId, feedback } = action.payload;
      const company = state.companiesMap[state.selectedCompanyId.toString()];

      if (company) {
        const headlineIndex = company.headlines.findIndex((headline) => headline.id === headlineId);

        if (headlineIndex !== -1) {
          const headline = company.headlines[headlineIndex];
          const existingFeedbackIndex = headline.sourcerFeedback.findIndex(
            (f) => f.sourcerId === sourcerId,
          );

          const newFeedback = {
            sourcerId,
            liked: feedback === 'Like',
            disliked: feedback === 'Dislike',
          };

          if (existingFeedbackIndex !== -1) {
            // Update existing feedback
            headline.sourcerFeedback[existingFeedbackIndex] = newFeedback;
          } else {
            // Add new feedback
            headline.sourcerFeedback.push(newFeedback);
          }

          // Update the headline in the company
          company.headlines[headlineIndex] = { ...headline };

          // Update the company in the companiesMap
          state.companiesMap[state.selectedCompanyId.toString()] = { ...company };
        }
      }
    },
  },
  extraReducers(builder) {
    // Handle getOneCompany
    builder.addCase(getOneCompany.pending, (state) => {
      if (!state.isFetchingAdditional) {
        state.isLoading = true;
      }
      state.error = null;
    });

    builder.addCase(getOneCompany.fulfilled, (state, action) => {
      if (!action.payload) return;
      const updatedCompany = { ...action.payload };
      state.companiesMap[updatedCompany.id.toString()] = updatedCompany;

      if (!state.isFetchingAdditional) {
        state.isLoading = false;
      }

      // Update fetch progress
      state.fetchProgress =
        (Object.keys(state.companiesMap).length / state.companiesAssignments.length) * 100;
    });

    builder.addCase(getOneCompany.rejected, (state, action) => {
      if (!state.isFetchingAdditional) {
        state.isLoading = false;
      }
      state.companiesAssignments = state.companiesAssignments.filter(
        (assignment) => assignment.companyAffinityId !== action.meta.arg.toString(),
      );
      state.error = action.error.message || errorMessage;
    });

    // Handle getAllCompanies
    builder.addCase(getAllCompanies.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    });

    builder.addCase(getAllCompanies.fulfilled, (state, action) => {
      return {
        ...state,
        companiesAssignments: action.payload,
        isLoading: false,
      };
    });

    builder.addCase(getAllCompanies.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error.message || errorMessage,
      };
    });

    // Handle postEvent
    builder.addCase(postEvent.pending, (state) => {
      return {
        ...state,
        error: null,
      };
    });

    builder.addCase(postEvent.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      };
    });

    builder.addCase(postEvent.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error.message || errorMessage,
      };
    });

    // Handle postHeadlineFeedback
    builder.addCase(postHeadlineFeedback.pending, (state) => {
      state.error = null;
    });

    builder.addCase(postHeadlineFeedback.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(postHeadlineFeedback.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || errorMessage;
    });

    // Handle getFundraisingData
    builder.addCase(getFundraisingData.pending, (state) => {
      return {
        ...state,
        error: null,
      };
    });

    builder.addCase(getFundraisingData.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        companiesMap: {
          ...state.companiesMap,
          [state.selectedCompanyId.toString()]: {
            ...state.companiesMap[state.selectedCompanyId.toString()],
            funding: {
              ...state.companiesMap[state.selectedCompanyId.toString()].funding,
              history: action.payload.history
                .filter((deal: FundingSeries) => {
                  const companyFundingLast =
                    state.companiesMap[state.selectedCompanyId.toString()].funding.last;
                  return (
                    deal.date !== companyFundingLast.date &&
                    deal.amount !== companyFundingLast.amount &&
                    deal.round !== companyFundingLast.round
                  );
                })
                .sort((deal1: FundingSeries, deal2: FundingSeries) => {
                  return new Date(deal2.date).getTime() - new Date(deal1.date).getTime();
                }),
            },
          },
        },
        noMoreDeals: action.payload.stats.page === action.payload.stats.lastPage,
      };
    });

    builder.addCase(getFundraisingData.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error.message || errorMessage,
      };
    });

    // Handle undoReview
    builder.addCase(undoReview.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error.message || errorMessage,
      };
    });

    // Handle fetchAdditionalCompanies
    builder.addCase(fetchAdditionalCompanies.pending, (state) => {
      state.isFetchingAdditional = true;
    });
    builder.addCase(fetchAdditionalCompanies.fulfilled, (state) => {
      state.isFetchingAdditional = false;
    });
    builder.addCase(fetchAdditionalCompanies.rejected, (state) => {
      state.isFetchingAdditional = false;
    });
  },
});

export const { setSelectedCompany, setFetchProgress, setHeadlineFeedback } = companiesSlice.actions;

export default companiesSlice.reducer;
