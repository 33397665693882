import { AffinityList as AffinityListModel } from 'api/company/company.model';
import { Skeleton } from '@/components/ui/skeleton';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { Card, CardContent } from '@/components/ui/card';

interface AffinityListProps {
  affinityLists: AffinityListModel[];
  isLoading: boolean;
}
const AffinityList = ({ affinityLists, isLoading }: AffinityListProps) => {
  return (
    <Card className="bg-sidebar-grey p-4">
      <CardContent className="p-0">
        {isLoading ? (
          <div className="flex flex-col gap-3 p-0">
            <Skeleton className="w-1/2 h-6" />
            <Skeleton className="w-3/4 h-4" />
          </div>
        ) : (
          <div className="flex flex-col gap-3 text-lg font-semibold p-0">
            <h3>Affinity List</h3>
            {affinityLists.map((list) => {
              return (
                <a
                  href={list.url}
                  target="_blank"
                  className="flex gap-2 text-sm items-center font-normal text-blue-700 underline"
                  key={list.id}
                  rel="noreferrer"
                >
                  <p>{list.name}</p>
                  <ChevronRightIcon />
                </a>
              );
            })}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export { AffinityList };
