import httpClient from 'common/http/httpClient';
import * as Sentry from '@sentry/react';
import { User } from './user.model';

interface Response {
  data: { data: any };
}

export interface IUserService {
  getMe: () => Promise<User>;
  getAvailableUsers: () => HttpPromise<User[]>;
}

const UserService = (): IUserService => {
  return {
    getMe: async (): Promise<User> => {
      try {
        const response = (await httpClient.get(`/sourcer/me`)) as Response;
        return response.data.data as User;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    getAvailableUsers: async (): Promise<User[]> => {
      try {
        const response = (await httpClient.get(`/sourcer/all`)) as Response;
        return response.data.data as User[];
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
  };
};

export default UserService();
