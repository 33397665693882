import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';

interface ChartProps {
  chartData: {
    [key: string]: string | number;
  }[];
}

const Chart = ({ chartData }: ChartProps) => {
  const chartConfig: ChartConfig = Object.keys(chartData[0])
    .filter((key) => key !== 'date')
    .map((key, index) => ({
      label: key,
      color: `hsl(var(--chart-${index + 1}))`,
    }))
    .reduce((acc, curr) => ({ ...acc, [curr.label]: curr }), {}) satisfies ChartConfig;

  return (
    <Card className="flex flex-col justify-center h-full py-6 ">
      <CardContent className="p-0">
        <ChartContainer config={chartConfig}>
          <AreaChart
            accessibilityLayer
            data={chartData}
            margin={{
              left: -10,
              right: 30,
              bottom: 0,
              top: 10,
            }}
          >
            <CartesianGrid />
            <XAxis
              dataKey="date"
              tickLine={false}
              tickMargin={8}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <YAxis dataKey="Total" tickLine={false} tickMargin={8} />
            <ChartTooltip cursor={false} content={<ChartTooltipContent indicator="dot" />} />
            {Object.keys(chartConfig).map((key, index) => (
              <Area
                key={key}
                dataKey={key}
                type="linear"
                fill={`url(#gradient${index})`}
                fillOpacity={0.4}
                stroke={chartConfig[key].color}
              />
            ))}
            <defs>
              {Object.keys(chartConfig).map((key, index) => (
                <linearGradient key={key} id={`gradient${index}`} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={chartConfig[key].color} stopOpacity="0.8" />
                  <stop offset="80%" stopColor={chartConfig[key].color} stopOpacity="0" />
                </linearGradient>
              ))}
            </defs>
          </AreaChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex justify-between pt-5">
        <div className="flex flex-wrap gap-4">
          {Object.keys(chartConfig).map((key) => (
            <div key={key} className="flex items-center gap-2">
              <span
                className="inline-block w-2 h-2"
                style={{ backgroundColor: chartConfig[key].color }}
              ></span>
              <span className="text-xs">{key}</span>
            </div>
          ))}
        </div>
      </CardFooter>
    </Card>
  );
};

export { Chart };
