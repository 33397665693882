import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as ThumbUpSvg } from 'assets/icons/thumb-up.svg';
import { ReactComponent as ThumbDownSvg } from 'assets/icons/thumb-down.svg';
import useCompanies from 'hooks/useCompanies';
import useUser from 'hooks/useUser';
import { Headline as HeadlineProps } from 'api/company/company.model';
import useSkeletonLoader from 'hooks/useSkeletonLoader';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Textarea } from '@/components/ui/textarea';
import { Cross1Icon } from '@radix-ui/react-icons';
import { Skeleton } from '@/components/ui/skeleton';

const Headline: React.FC<HeadlineProps> = ({
  id,
  companyProfileId,
  description,
  thumbnail,
  title,
  date,
  url,
  source,
  sourcerFeedback,
}) => {
  const { submitHeadlineFeedback } = useCompanies();
  const { user } = useUser();
  const [likeActive, setLikeActive] = useState(false);
  const [dislikeActive, setDislikeActive] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [comment, setComment] = useState('');
  const { loading } = useSkeletonLoader();

  const thumbsUpClassName = useMemo(() => {
    return likeActive ? 'fill-green-500' : 'fill-gray-400';
  }, [likeActive]);
  const thumbsDownClassName = useMemo(() => {
    return dislikeActive ? 'fill-red-500' : 'fill-gray-400';
  }, [dislikeActive]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const feedback = sourcerFeedback.find((f) => f.sourcerId === user.user.id);
    setLikeActive(feedback?.liked ?? false);
    setDislikeActive(feedback?.disliked ?? false);
  }, [sourcerFeedback, user.user.id]);

  const handleLikeClick = () => {
    if (!likeActive) {
      setLikeActive(true);
      setDislikeActive(false);
      submitHeadlineFeedback(id, user.user.id, companyProfileId, 'Like');
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDislikeActive(false);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const handleSubmitDislike = () => {
    handleClose();
    submitHeadlineFeedback(id, user.user.id, companyProfileId, 'Dislike', comment);
    setLikeActive(false);
    setDislikeActive(true);
    setComment('');
  };

  useEffect(() => {
    const handleScroll = () => {
      handleClose();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (loading) {
    return <Skeleton className="w-full h-20" />;
  }

  return (
    <>
      <div className="flex gap-3">
        <img className="size-14" src={thumbnail} />
        <div className="flex flex-col gap-2">
          <span className="text-sm text-gray-500">
            {source} · {date}
          </span>
          <a href={url} target="_blank" rel="noreferrer">
            <h3 className="text-sm text-primary font-medium leading-6 line-clamp-2 w-11/12">
              {title}
            </h3>
          </a>
          <p className="text-[12px] text-gray-500 leading-5">{description}</p>
          <div className="flex gap-2 pt-3">
            <Popover open={Boolean(anchorEl)}>
              <PopoverTrigger asChild>
                <Button
                  onClick={handleClick}
                  className="size-7 rounded-sm bg-transparent hover:bg-transparent shadow-none border border-solid border-gray-300 px-2 group"
                >
                  <ThumbDownSvg className={`${thumbsDownClassName} group-hover:fill-gray-500`} />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-80">
                <div className="flex flex-col gap-2">
                  <div className="flex w-full items-center font-sans text-md font-semibold justify-between gap-2">
                    <h2>What could we improve?</h2>
                    <Button
                      onClick={handleClose}
                      className="bg-transparent hover:bg-transparent shadow-none"
                    >
                      <Cross1Icon className="text-black" />
                    </Button>
                  </div>
                  <Textarea
                    onChange={handleCommentChange}
                    placeholder="Text here"
                    className="resize-none"
                    cols={30}
                    rows={8}
                  />
                  <div>
                    <Button
                      onClick={handleClose}
                      className="bg-transparent hover:bg-transparent font-semibold text-sm text-black font-sans shadow-none"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmitDislike}
                      className="font-semibold text-sm font-sans"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
            <Button
              onClick={handleLikeClick}
              className="size-7 rounded-sm bg-transparent hover:bg-transparent shadow-none border border-solid border-gray-300 px-2 group"
            >
              <ThumbUpSvg className={`${thumbsUpClassName} group-hover:fill-gray-500`} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Headline;
