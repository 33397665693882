import { FundingSeries } from '@/api/company/company.model';
import { Card, CardContent, CardTitle } from '../ui/card';
import { CardSkeleton } from '../ui/cardSkeleton';
import { FundingTable } from './fundingTable';
import InvestorsList from './investorsList';

interface FundraisingHistoryProps {
  fundraisingData: FundingSeries[];
  investors: string[];
  isLoading: boolean;
}

const FundraisingHistory = ({ fundraisingData, investors, isLoading }: FundraisingHistoryProps) => {
  if (isLoading) return <CardSkeleton />;
  return (
    <Card className="bg-card-grey flex flex-col h-fit">
      <CardTitle className="text-lg font-bold p-4">Fundraising History</CardTitle>
      <CardContent className="px-4 flex-grow">
        <Card className="px-3 py-6 flex flex-col h-full">
          <CardContent className="p-0 flex flex-col justify-start gap-6 h-full">
            <FundingTable fundraisingData={fundraisingData} />
            <InvestorsList investors={investors} />
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export { FundraisingHistory };
