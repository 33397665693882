import { WebsiteIcon } from '@/components/ui/websiteIcon';
import { ButtonAction, DONT_REASONS, SKIP_REASONS } from '@/utils/constants';
import useCompanies from '@/hooks/useCompanies';
import { useEffect, useState } from 'react';
import useAppNavigation from '@/hooks/useAppNavigation';
import useUser from '@/hooks/useUser';
import posthog from 'posthog-js';
import { Sidebar } from '@/components/layout/Sidebar';
import { FundraisingHistory } from '@/components/shared/fundraisingHistory';
import { AffinityNotes } from '@/components/shared/affinityNotes';
import { InstagramProfile } from '@/components/shared/instagramProfile';
import { Headcount } from '@/components/shared/headcount';
import { HeadlinesList } from '@/components/shared/headlinesList';
import { Header } from '@/components/layout/Header';
import { Navbar } from '@/components/layout/Navbar';
import classNames from 'classnames';
import toastDelete from 'assets/icons/toast-delete.svg';
import toastSuccess from 'assets/icons/toast-success.svg';
import { useToast } from '@/hooks/use-toast';

import { ReasonsAlertDialog } from '@/components/shared/reasonsAlertDialog';
import Loader from '@/components/layout/Loader';
import { Skeleton } from '@/components/ui/skeleton';
import useMediaQuery from '@/hooks/useMediaQuery';

const CompanyProfilePage = () => {
  const { fetchAvailableUsers, availableUsers } = useUser();
  const { fetchAllCompanies, companiesAssignments, company, error, isLoading } = useCompanies();
  const { fetchUserDataFromAPI } = useUser();
  const [isModalOpen] = useState(false);
  const [isDontPursueModalOpen, setIsDontPursueModalOpen] = useState(false);
  const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const navigate = useAppNavigation();
  const { toast } = useToast();

  const toggleDialogs = (actionType: string) => {
    switch (actionType) {
      case ButtonAction.DontPursue:
        setIsDontPursueModalOpen(!isDontPursueModalOpen);
        break;
      case ButtonAction.Skip:
        setIsSkipModalOpen(!isSkipModalOpen);
        break;
      default:
        break;
    }
  };

  const handleReachOut = () => {
    toast({
      title: 'This company has been included in your Affinity List',
      description: <img src={toastSuccess} alt="successIcon" />,
      className:
        'bg-[#e7f5f2] border-b border-solid border-[#089983] fixed top-20 left-0 w-screen h-20',
    });
  };

  const handleDontPursue = () => {
    toast({
      title: 'This company has been dismissed.',
      description: <img src={toastDelete} alt="deleteIcon" />,
      className:
        'bg-red-100 border-b border-solid border-red-500 fixed top-20 left-0 w-screen h-20',
    });
  };

  useEffect(() => {
    fetchUserDataFromAPI();
    fetchAllCompanies();
    fetchAvailableUsers();
  }, [fetchAllCompanies, fetchAvailableUsers, fetchUserDataFromAPI]);

  useEffect(() => {
    if (
      companiesAssignments &&
      companiesAssignments.filter((assignment) => !assignment.hasReview).length > 0
    ) {
      const firstAssignmentWithoutReview = companiesAssignments.find(
        (assignment) => !assignment.hasReview,
      );
      if (firstAssignmentWithoutReview) {
        navigate(`/company-profile/${firstAssignmentWithoutReview.companyAffinityId}`);
      }
    }
  }, [companiesAssignments]);

  if (error) {
    throw new Error('No company data available');
  }

  if (isLoading || !company || !Object.keys(company).length) {
    return <Loader />;
  }

  return (
    <div
      className={classNames('font-sans min-h-screen bg-gray-100', { isLoading })}
      data-cy="company-profile-page"
    >
      <Header toggleDialogs={toggleDialogs} isModalOpen={isModalOpen} onReachOut={handleReachOut} />
      <div className="h-full max-w-8xl mx-auto flex flex-col mt-28 pt-16 px-4 pb-52 gap-8 sm:mt-24 md:mt-32 md:flex-row md:gap-12 lg:mt-24">
        <div className="size-full md:w-1/4">
          <div className="flex flex-col gap-3">
            {isLoading ? (
              <div className="py-5 flex gap-2 items-center">
                <Skeleton className="size-16" />
                <Skeleton className="w-28 h-7 bg-gray-200" />
              </div>
            ) : (
              <a
                href={company?.websites?.[0] || ''}
                className="max-w-28 max-h-28"
                target="_blank"
                data-cy="company-logo"
                rel="noreferrer"
              >
                <div className="flex items-center justify-start gap-4">
                  {company.logo && <img src={company.logo} alt="Logo" className="max-w-16" />}
                  <span className="text-xl font-bold max-w-full" data-cy="company-name">
                    {company?.name || ''}
                  </span>
                </div>
              </a>
            )}
            <div className="flex gap-4 overflow-y-hidden md:flex-col md:gap-0 border-gray-300 border-solid border-t md:border-0">
              {company.websites && company.websites.length > 0 && (
                <div
                  className="flex gap-4 items-center py-5 md:py-4 md:border-gray-300 md:border-solid md:border-t"
                  data-cy="company-social-websites"
                >
                  {company.websites?.map((website: string) => (
                    <WebsiteIcon
                      key={website}
                      website={website}
                      onClick={() => {
                        posthog?.capture('link_click', {
                          link_type: website,
                          company_id: company.id,
                          company_name: company.name,
                        });
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          <Sidebar company={company} isLoading={isLoading} data-cy="company-page-body" />
        </div>
        <div className="flex flex-col gap-8 md:w-3/4">
          <div className="flex flex-col w-full gap-8 lg:flex-row" data-cy="company-page-content">
            <div className="flex flex-col w-full gap-8 lg:w-1/2">
              {company.funding ? (
                <FundraisingHistory
                  isLoading={isLoading}
                  fundraisingData={[company.funding.last, ...company.funding.history]}
                  investors={company.investors}
                />
              ) : null}
              {!isDesktop ? (
                company.headcount && company.headcount.dates.length > 0 ? (
                  <Headcount
                    isLoading={isLoading}
                    dates={company.headcount.dates}
                    headcounts={company.headcount.departmentsCounts}
                  />
                ) : null
              ) : (
                <AffinityNotes notes={company.notes || []} loading={isLoading} />
              )}
            </div>
            <div className="flex flex-col w-full gap-8 lg:w-1/2">
              {isDesktop ? (
                company.headcount && company.headcount.dates.length > 0 ? (
                  <Headcount
                    isLoading={isLoading}
                    dates={company.headcount.dates}
                    headcounts={company.headcount.departmentsCounts}
                  />
                ) : null
              ) : (
                <AffinityNotes notes={company.notes || []} loading={isLoading} />
              )}
              <InstagramProfile name={company.name} company={company} loading={isLoading} />
            </div>
          </div>
          {company.headlines && company.headlines.length > 0 && (
            <HeadlinesList headlines={company.headlines} loading={isLoading} />
          )}
        </div>
      </div>
      {isMobile ? <Navbar toggleDialogs={toggleDialogs} /> : null}
      <ReasonsAlertDialog
        isOpen={isDontPursueModalOpen}
        setIsOpen={setIsDontPursueModalOpen}
        reasons={DONT_REASONS}
        actionType={ButtonAction.DontPursue}
        textAreaReason={DONT_REASONS[DONT_REASONS.length - 1].id}
        feedbackToastAction={handleDontPursue}
      />
      <ReasonsAlertDialog
        isOpen={isSkipModalOpen}
        setIsOpen={setIsSkipModalOpen}
        reasons={SKIP_REASONS}
        actionType={ButtonAction.Skip}
        availableUsers={availableUsers}
        textAreaReason={SKIP_REASONS[SKIP_REASONS.length - 1].id}
      />
    </div>
  );
};

export default CompanyProfilePage;
