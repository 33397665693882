import * as Sentry from '@sentry/react';
import httpClient from 'common/http/httpClient';
import {
  Company,
  ICompanyService,
  EventData,
  DataFundraising,
  UndoReviewData,
  HeadlineFeedbackData,
  Headline,
  FundingSeries,
} from './company.model';

interface Response {
  data: { data: unknown };
}

class ApiError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ApiError';
  }
}

const CompanyService = (): ICompanyService => {
  return {
    getOneCompany: async (companyId: string): Promise<Company> => {
      try {
        const response = (await httpClient.get(`/company/${companyId}`)) as Response;
        return response.data.data as Company;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    getAllCompanies: async (): Promise<string[]> => {
      try {
        const response = (await httpClient.get(`/company/all`)) as Response;
        return response.data.data as string[];
      } catch (error) {
        Sentry.captureException(error);
        throw new ApiError('Failed to fetch companies');
      }
    },

    submitEvent: async (data: EventData): Promise<string> => {
      try {
        const response = (await httpClient.post(`/company/event`, { data })) as Response;
        return response.data.data as string;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    submitHeadlineFeedback: async (data: HeadlineFeedbackData): Promise<Headline> => {
      try {
        const response = (await httpClient.post(`/headline/feedback/${data.headlineId}`, {
          data,
        })) as Response;
        return response.data.data as Headline;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    undoReview: async (data: UndoReviewData): Promise<string> => {
      try {
        const { sourcerId, companyId } = data;
        const response = (await httpClient.delete(
          `/sourcer/${sourcerId}/undo-event/${companyId}`,
        )) as Response;
        return response.data.data as string;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    getFundraisingData: async ({
      snapshotId,
      companyId,
      page,
    }: DataFundraising): Promise<FundingSeries[]> => {
      try {
        const response = (await httpClient.get(
          `/company/deals?snapshotId=${snapshotId}&companyId=${companyId}&page=${page}`,
        )) as Response;
        return response.data.data as FundingSeries[];
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
  };
};

export default CompanyService();
