import { Button } from '@/components/ui/button';
import useCompanies from '@/hooks/useCompanies';
import { cn } from '@/lib/utils';
import { ButtonAction } from '@/utils/constants';
import dangerMobile from '@/assets/icons/danger-mobile.svg';
import reachMobile from '@/assets/icons/reach-mobile.svg';
import skipMobile from '@/assets/icons/skip-mobile.svg';
import trackMobile from '@/assets/icons/track-mobile.svg';

interface StickyNavbarProps {
  toggleDialogs: (buttonAction: string) => void;
}

const BUTTON_CONTAINER_CLASSNAME = 'flex flex-col justify-between items-center p-4 w-20';
const BUTTON_CLASSNAME =
  'rounded-full size-16 bg-white shadow-custom border border-solid border-opacity-40';
const CENTER_BUTTON_CONTAINER_CLASSNAME = 'flex flex-col gap-1 items-center';
const CENTER_BUTTON_CLASSNAME =
  'rounded-full size-10 bg-white shadow-custom border border-solid border-opacity-40';

const Navbar = ({ toggleDialogs }: StickyNavbarProps) => {
  const { nextCompany } = useCompanies();

  const trackHandler = () => {
    nextCompany();
  };

  const skipHandler = () => {
    toggleDialogs(ButtonAction.Skip);
  };

  const dontPursueHandler = () => {
    toggleDialogs(ButtonAction.DontPursue);
  };

  return (
    <div className=" flex justify-around border border-solid border-navbar-violet rounded-t-3xl w-screen fixed bottom-0 left-0 right-0 bg-white p-2 items-center">
      <div className={cn(BUTTON_CONTAINER_CLASSNAME, ' gap-2')}>
        <Button
          onClick={dontPursueHandler}
          className={cn(BUTTON_CLASSNAME, 'border-button-dontPursue')}
        >
          <img src={dangerMobile} alt="Icon" className="absolute" />
        </Button>
        <span className="text-button-dontPursue text-center">{"Don't Pursue"}</span>
      </div>
      <div className={cn(BUTTON_CONTAINER_CLASSNAME, 'gap-6 -mt-10')}>
        <div className={CENTER_BUTTON_CONTAINER_CLASSNAME}>
          <Button
            onClick={trackHandler}
            className={cn(CENTER_BUTTON_CLASSNAME, 'border-button-track')}
          >
            <img src={trackMobile} alt="Icon" className="absolute" />
          </Button>
          <span className="text-button-track">Track</span>
        </div>
        <div className={CENTER_BUTTON_CONTAINER_CLASSNAME}>
          <Button onClick={skipHandler} className={cn(CENTER_BUTTON_CLASSNAME, 'border-gray-200')}>
            <img src={skipMobile} alt="Icon" className="absolute" />
          </Button>
          <span className="text-gray-400">Skip</span>
        </div>
      </div>
      <div className={cn(BUTTON_CONTAINER_CLASSNAME, ' gap-2')}>
        <Button onClick={trackHandler} className={cn(BUTTON_CLASSNAME, 'border-button-success')}>
          <img src={reachMobile} alt="Icon" className="absolute" />
        </Button>
        <span className="text-button-success text-center">Reach Out</span>
      </div>
    </div>
  );
};

export { Navbar };
