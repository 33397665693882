import { Company } from '@/api/company/company.model';
import { Card, CardContent } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { AffinityList } from '@/components/shared/affinityList';
import { ProspectOrigin } from '../shared/prospectOrigin';
import InfoSidebar from '../shared/infoSidebar';
import CollapsibleDescription from '../shared/collapsibleDescription';

interface SidebarProps {
  company: Company;
  isLoading: boolean;
  className?: string;
}

const CARD_CLASS = 'rounded-2xl shadow-custom bg-sidebar-grey';

const Sidebar = ({ company, isLoading, className }: SidebarProps) => {
  return (
    <div className="flex flex-col gap-3">
      <Card className={cn(CARD_CLASS, className)}>
        <CardContent className="p-0">
          {company.description && <CollapsibleDescription description={company.description} />}
          <InfoSidebar company={company} isLoading={isLoading} />
        </CardContent>
      </Card>
      {company.prospectOrigin ? (
        <Card className={cn(CARD_CLASS, className)}>
          <CardContent className="p-0">
            <ProspectOrigin prospectOrigin={company.prospectOrigin} isLoading={isLoading} />
          </CardContent>
        </Card>
      ) : null}
      {company.affinityLists && company.affinityLists.length > 0 ? (
        <Card className={cn(CARD_CLASS, className)}>
          <CardContent className="p-0">
            <AffinityList affinityLists={company.affinityLists} isLoading={isLoading} />
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
};

export { Sidebar };
