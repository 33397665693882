import { FC } from 'react';
import { Skeleton } from '@/components/ui/skeleton';

interface InfoProps {
  label: string;
  icon: React.ReactNode;
  title: string;
  position: 'left' | 'right';
  isLoading: boolean;
}

const Info2: FC<InfoProps> = ({ label, icon, title, position, isLoading }) => {
  const isIconOnLeft = position === 'left';

  return (
    <div className="flex items-center gap-4" data-cy="info-item">
      {isLoading ? (
        <>
          <Skeleton className="w-5/6 h-6 bg-gray-300" />
          <Skeleton className="w-1/2 h-6 bg-gray-200" />
        </>
      ) : (
        <>
          {isIconOnLeft && <div className="size-6">{icon}</div>}
          <div className="flex flex-col gap-1">
            <h3 className="text-sm font-medium">{title}</h3>
            <p className="text-sm text-gray-500">
              {label &&
                label.split('\n').map((str, index, array) =>
                  array.length - 1 === index ? (
                    str
                  ) : (
                    <>
                      {str}
                      <br />
                    </>
                  ),
                )}
            </p>
          </div>
          {!isIconOnLeft && <div className="size-6">{icon}</div>}
        </>
      )}
    </div>
  );
};

export default Info2;
