const useViewTransition = () => {
  const startViewTransition = (callback: (...params: any[]) => void) => {
    if (document.startViewTransition) {
      return document.startViewTransition(() => {
        callback();
      });
    }
    callback();
    return undefined;
  };

  return { startViewTransition };
};

export default useViewTransition;
