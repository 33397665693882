import { useState, useEffect } from 'react';
import useCompanies from './useCompanies';

const useSkeletonLoader = () => {
  const [loading, setLoading] = useState(true);
  const { company } = useCompanies();

  useEffect(() => {
    setLoading(true);

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [company.id]);

  return { loading };
};

export default useSkeletonLoader;
