import { fetchUserData, getAvailableUsers } from 'store/slices/userSlice';
import { useCallback, useEffect } from 'react';
import { RootState } from 'store/index';
import { usePostHog } from 'posthog-js/react';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

const useUser = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const { availableUsers, error } = user;
  const posthog = usePostHog();

  const fetchUserDataFromAPI = useCallback(async () => {
    dispatch(fetchUserData());
  }, [dispatch]);

  useEffect(() => {
    posthog?.identify(user.user.id, {
      email: user.user.email,
    });
  }, [user, posthog]);

  const fetchAvailableUsers = useCallback(async () => {
    await dispatch(getAvailableUsers());
  }, [dispatch]);

  return {
    user,
    fetchUserDataFromAPI,
    fetchAvailableUsers,
    availableUsers,
    error,
  };
};

export default useUser;
