import { FC } from 'react';

import { Alert, AlertTitle } from '@/components/ui/alert';
import { Separator } from '@/components/ui/separator';
import { Card, CardContent } from '@/components/ui/card';
import { InfoCircledIcon } from '@radix-ui/react-icons';

interface InvestorsListProps {
  investors: string[];
}

const InvestorsList: FC<InvestorsListProps> = ({ investors }) => (
  <div className="flex flex-col gap-4 pt-4">
    <div className="flex flex-col gap-2">
      <h2 className="text-sm font-semibold">Investors</h2>
      <Separator />
    </div>
    <div className="flex flex-wrap gap-2 max-h-[300px] overflow-y-auto">
      {investors.length > 0 ? (
        investors.map((investor, index) => (
          <Card
            className="rounded-sm bg-[#f4f7ff] text-sm shadow-none border border-solid border-slate-200"
            key={investor + index}
          >
            <CardContent className="p-1">
              <p className="text-[12px] font-sans capitalize">{investor}</p>
            </CardContent>
          </Card>
        ))
      ) : (
        <Alert className="bg-[#deecfe] border border-solid border-[#6eacf8] w-2/3">
          <div className="flex gap-2">
            <InfoCircledIcon />
            <AlertTitle>No investors data available.</AlertTitle>
          </div>
        </Alert>
      )}
    </div>
  </div>
);

export default InvestorsList;
