import { useCallback, useMemo } from 'react';
import { RootState } from 'store/index';
import {
  setSelectedCompany,
  getFundraisingData,
  undoReview,
  postHeadlineFeedback,
} from 'store/slices/companiesSlice';
import paths from 'routes/paths';
import { Company, HeadlineFeedbackData } from 'api/company/company.model';
import { ButtonAction, EventTypeToButtonActionMap } from '../utils/constants';
import { getOneCompany, getAllCompanies, postEvent } from '../store/slices/companiesSlice';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';
import useAppNavigation from './useAppNavigation';

const useCompanies = () => {
  const navigate = useAppNavigation();
  const dispatch = useAppDispatch();
  const companies = useAppSelector((state: RootState) => state.companies);
  const {
    selectedCompanyId,
    companiesMap,
    companiesAssignments,
    isLoading,
    noMoreDeals,
    selectedCompanyListEntryId,
    error,
  } = companies;

  const getSelectedCompany = useMemo((): Company => {
    const selectedCompany = companiesMap[selectedCompanyId] || companiesMap[0];

    return selectedCompany
      ? {
          ...selectedCompany,
          listEntryId: selectedCompanyListEntryId,
        }
      : ({} as Company);
  }, [companiesMap, selectedCompanyId, selectedCompanyListEntryId]);

  const nextCompany = () => {
    const currentIndex = companiesAssignments.findIndex(
      (company) => company.companyAffinityId === selectedCompanyId.toString(),
    );

    if (currentIndex + 1 >= companiesAssignments.length) {
      navigate(paths.NO_MORE_COMPANIES);
    } else {
      dispatch(setSelectedCompany(companiesAssignments[currentIndex + 1]));
      navigate(`/company-profile/${companiesAssignments[currentIndex + 1].companyAffinityId}`);
    }
  };

  const previousCompany = () => {
    const currentIndex = companiesAssignments.findIndex(
      (company) => company.companyAffinityId === selectedCompanyId.toString(),
    );

    if (currentIndex > 0) {
      dispatch(setSelectedCompany(companiesAssignments[currentIndex - 1]));
      navigate(`/company-profile/${companiesAssignments[currentIndex - 1].companyAffinityId}`);
    }
  };

  const fetchOneCompany = useCallback(
    async (companyId: string) => {
      dispatch(getOneCompany(companyId));
    },
    [dispatch],
  );

  const fetchAllCompanies = useCallback(async () => {
    await dispatch(getAllCompanies()).then(() => {
      if (companiesAssignments.length > 0) {
        dispatch(setSelectedCompany(companiesAssignments[0]));
      }
    });
  }, [dispatch]);

  const submitCompanyEvent = useCallback(
    async (
      eventType: ButtonAction,
      snapshotId: string,
      reasons: string[],
      note: string,
      listEntryId: string,
      sourcerToAssignId = '',
    ) => {
      dispatch(
        postEvent({
          eventType: EventTypeToButtonActionMap[eventType],
          snapshotId,
          reasons,
          note,
          listEntryId,
          sourcerToAssignId,
        }),
      );
    },
    [dispatch],
  );

  const handleUndoReview = useCallback(
    async (sourcerId: string, companyId: string) => {
      try {
        await dispatch(undoReview({ sourcerId, companyId }));
      } catch (error) {
        console.error('Error dispatching undoReview:', error);
      }
    },
    [dispatch],
  );

  const submitHeadlineFeedback = useCallback(
    async (
      headlineId: string,
      sourcerId: string,
      companyProfileId: string,
      feedback: 'Like' | 'Dislike',
      comment?: string,
    ) => {
      const feedbackData: HeadlineFeedbackData = {
        headlineId,
        sourcerId,
        companyProfileId,
        feedback,
        comment,
      };

      try {
        await dispatch(postHeadlineFeedback(feedbackData));
      } catch (error) {
        console.error('Error submitting headline feedback:', error);
      }
    },
    [dispatch],
  );

  const fetchFundraisingData = useCallback(
    async (snapshotId: string, companyId: string) => {
      const { funding } = getSelectedCompany;
      const newPage: number = Math.floor(funding.history.length / 5);

      dispatch(getFundraisingData({ snapshotId, companyId, page: newPage + 1 }));
    },
    [dispatch],
  );

  return {
    isLoading,
    error,
    company: getSelectedCompany,
    companiesMap,
    companiesAssignments,
    nextCompany,
    previousCompany,
    fetchOneCompany,
    fetchAllCompanies,
    submitCompanyEvent,
    undoReview: handleUndoReview,
    fetchFundraisingData,
    noMoreDeals,
    submitHeadlineFeedback,
  };
};

export default useCompanies;
