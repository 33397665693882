import React from 'react';
import ReactDOM from 'react-dom/client';
import setupSentry from 'config/sentry/sentryConfig';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import theme from 'theme/theme';

import Router from 'routes/Router';
import store from 'store/index';

import 'common/language';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Toaster } from './components/ui/toaster';
import 'react-toastify/dist/ReactToastify.css';

import '@/global.css';
import ErrorBoundary from './components/errorBoundary/errorBoundary';

setupSentry();

posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY || '', {
  api_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary>
      <PostHogProvider>
        <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID as string}>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <Toaster />
              <RouterProvider router={Router} />
            </Provider>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </PostHogProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
