import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EN from 'common/language/locales/en.json';
import ES from 'common/language/locales/es.json';

i18n.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en: { translation: EN },
    es: { translation: ES },
  },
});

export default i18n;
