import { useMemo, useState } from 'react';

import useSkeletonLoader from 'hooks/useSkeletonLoader';
import { Skeleton } from '../ui/skeleton';
import { Button } from '../ui/button';

const TRUNCATED_DESCRIPTION_MAX_WORDS = 55;

interface CollapsibleDescriptionProps {
  description: string;
  callback?: () => void;
}

function CollapsibleDescription({ description, callback }: CollapsibleDescriptionProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { loading } = useSkeletonLoader();

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
    if (callback) {
      callback();
    }
  };

  const buttonLabel = isExpanded ? 'Show less' : 'Show more';

  const descriptionWordsLength = useMemo(() => {
    return description?.split(/(\s+)/).filter((x) => x.trim().length > 0).length;
  }, [description]);

  const truncatedDescription = useMemo(() => {
    if (!isExpanded && descriptionWordsLength > TRUNCATED_DESCRIPTION_MAX_WORDS) {
      return `${description?.split(' ').splice(0, TRUNCATED_DESCRIPTION_MAX_WORDS).join(' ')}...`;
    }

    return description;
  }, [isExpanded, descriptionWordsLength, description]);

  return (
    <div className="flex flex-col gap-2 p-5">
      {loading ? (
        <Skeleton className="w-1/2 h-6 bg-gray-400" />
      ) : (
        <h2 className="text-lg font-semibold">Description</h2>
      )}

      {loading ? (
        <>
          <Skeleton className="w-11/12 h-6 bg-gray-200" />
          <Skeleton className="w-1/3 h-6 bg-gray-200" />
        </>
      ) : (
        <p className="text-sm text-gray-500 leading-6">{truncatedDescription}</p>
      )}

      {loading || descriptionWordsLength <= TRUNCATED_DESCRIPTION_MAX_WORDS ? null : (
        <Button
          className="bg-transparent shadow-none text-indigo-700 hover:bg-transparent flex justify-start p-0"
          onClick={toggleDescription}
          type="button"
        >
          {buttonLabel}
        </Button>
      )}
    </div>
  );
}

export default CollapsibleDescription;
